<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="row">    
               <div class="col-md-8 col-12">
                 <div class="card-header">
                <h3 class="card-title"> Customer List </h3>
              </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="srcpadmobie"> 
                <input class="form-control topmsrc5" type="text" v-model="searchQuery" placeholder="Search" />
                </div>
              </div>
            </div>
     
    </div>
    <section class="content">
      <div class="container-fluid">

        <div class="row">
          <section class="col-lg-12 connectedSortable">
            <div class="">
              <div class="">
                <div class="tab-content p-0">
                  <div class="chart tab-pane active">                  
                     
                         <div class="row">
                          
<div class="col-md-12">
<div class="card card-primary cardpad">              
              <!-- /.card-header -->
<div class="card-body">

  <table class="table">
    <tr>
      <th>Name</th>
      <th>Mobile No.</th>
      <th>Total Order</th>
      <th>Pending Order</th>
      <th>Complete Order</th>
      <th>Paid Amt.</th>
      <th>Total Invoice Amt.</th>
      <th>Action</th>
    </tr>

    <tr v-for="(user, index) in filteredResources" :key="user.id">
      <td><router-link class="linkdark" :to="{path:'/clientorder/' + user.id}"> {{ user.name }} </router-link></td>
      <td>{{ user.mobile_no }}</td>
      <td>{{ user.total_order }}</td>
      <td>{{ user.pending_order }}</td>
      <td>{{ user.completed_order }}</td>
      <td>Rs. {{ user.paid_amount }}</td>
      <td>Rs. {{ user.total_invoice_amount }}</td>
      <td>
        <div v-if="this.action.includes('15') && this.action.includes('16')">
<div class="row">
<div class="col-lg-12 col-xs-12">


<button v-if="this.action.includes('15')" @click="removeData(user.id, index)" class="btn btn-danger btn-xs"> 
  <i class="fa fa-times-circle"></i>  
</button>&nbsp;
<router-link  v-if="this.action.includes('16')" class="btn btn-success btn-xs" :to="{path:'/updatecustomer',query:{client_id: user.id,mobile_no: user.mobile_no,client_name: user.name}}"> 
  <i class="fas fa-edit"></i>  
</router-link></div>
</div>
</div>
      </td>
    </tr>
  </table>



               
              </div>
              <!-- /.card-body -->
            </div>

        </div>
      </div>    


                    <div class="hello">
                      <VueTailwindPagination
      :current="currentPage"
      :total="total"
      :per-page="perPage"
      @page-changed="onPageClick($event)"
    />
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination"
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {
  extends:defineComponent,
   components:{     
     VueTailwindPagination,
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar
      },
    data() {          
      return {
        resourcesaction:[],         
        company_id:this.$store.state.auth.user.data[0].company_id,
        role_id:this.$store.state.auth.user.data[0].role_id,
        user_id: this.$store.state.auth.user.data[0].id,
        id: this.$route.params.id,
        token: localStorage.token,   
        myData: null, 
        showbtn:this.id,    
        resources: [],
        searchQuery:'',
        currentPage: '1',
        perPage: '10',
        total: this.total,
        display: true,
        }
    },   
   computed: {
    filteredResources (){
      if(this.searchQuery){
      return this.resources.filter((user)=>{       
        return user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || user.mobile_no.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
      }else{
        return this.resources;
      }
    }
  },  

  methods: { 
    async getAction() {
          var response = await axios.post(
            `http://laundry.ezeehost.com:4909/ClientIspmateApi/getroleeditscreen`, {
              company_id: this.company_id, 
              user_id: this.user_id,
              role_id: this.role_id         
        })      
              this.resourcesaction = response.data;               
              this.action = response.data.data[0].action_ids;   
              this.role_name = response.data.data[0].role_name;             
        },
    showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
     showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },   
    
      async getData() {
      var response = await axios.post(
        `http://laundry.ezeehost.com:4909/ClientIspmateApi/customer-list`, {
          user_id: this.user_id, 
          company_id: this.company_id,
          page: this.currentPage, 
          search_data: '',                  
        });      
      this.resources = response.data.data.client_list;
      this.total = response.data.total_record;
      console.log(this.total)
       
    },
    onPageClick(event) {
      this.currentPage = event;
      this.getData(this.currentPage);
    },
    async removeData(id, index){   
      if(confirm("Do you really want to delete?")){   
      axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/deleteclient', {
              user_id: this.user_id,
              client_id: id,
              company_id: this.company_id
            })
                .then(response => {                  
                  this.resources.splice(index,1); 
                  this.showsuccessmsg(response.data.response);   
                  this.$router.push({path:'/clientlist'})                 
                  console.log(response.data.response)               
                })
                .catch(error => {        
                    console.log(error)
                    ///this.showfailedmsg('Something went wrong');    
            })

        }  
    }, 
   
    },

  mounted() {  
    setTimeout(() => {
      this.myData = 'Example Data';
    }, 2000);        
      this.getData();
      this.getAction();
  },

  

  };
</script>

<style>
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 1.25rem 1.25rem 1.25rem !important;
}
  @import '../../assets/dist/css/custom.css';        
</style>